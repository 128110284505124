// MFOOTER ===========================================

footer {
  background: var(--color-gray-5);
  border-top: 1px solid var(--color-gray-4);
  padding: 0;
}

.footer-wrapper {
  margin: auto;
  max-width: var(--max-width-content);
  padding: 1.5rem 0 1rem 0;
  width: 100%;

  a {
    color: var(--color-green-1);
    font-weight: 700;

    &[href^="tel:"] {
      color: var(--color-black);
      text-decoration: none;
      font-weight: normal;
    }
  }

  .l-3 {
    margin-bottom: 0.5rem;
  }

  h4 {
    margin-bottom: 0.5rem;
  }

  strong {
    display: block;
    margin-bottom: .5rem;
  }
}