.email-preview {
  overflow-x: hidden;

  img{
    &.logo {
      margin: 0 0 10px 0;
    }
  }

  .footer {
    p {
      margin: 5px 0;
      font-size: 12px;
    }
  }

  --color-email-gray-1: #f8f8f8;
  --color-email-green: #3faa35;
  --color-email-gray-2: #dbdbdb;
  --color-email-blue: #2d78f1;

  font-size: 10.5pt;
  font-family: arial;
  background: var(--color-email-gray-1);
  max-width: none;

  h1 {
    font-size: 20.5pt;
    margin-top: 0;
  }

  h2 {
    font-size: 20.5pt;
    margin-top: 0;
  }

  a {
    color: var(--color-email-blue);
  }

  .pay-now {
    background: var(--color-email-blue);
    color: white;
    border: 0;
    padding: 20px 40px;
    font-size: 1.5rem;
    width: 100%;
  }

  summary {
    font-size: 16.5pt;
    margin-bottom: 20px;
  }

  .sum {
    font-size: 1.5rem;
    font-weight: 700;
    margin-top: 20px;
  }

  .team-cash-info {
    margin-top: 10px;
    display: block;
  }

  .email-products {
    li {
      margin-bottom: 5px;
    }
  }

  .pay-date {
    margin-top:20px;
    border-top: 1px solid var(--color-email-gray-2);
    padding-top: 20px;
  }

  .row {
    max-width: 600px;
    margin: 20px auto;
    background: var(--color-white);
    padding: 20px;

    &.order-details {
      background: #f1f1f1;
      font-weight: normal;
  
      .bold {
        font-weight: 700;
      }
    }
  }
}