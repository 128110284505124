// VARIABLES ===========================================

// HTML variables
:root {

  // Colors ("1" is the darkest)
  --color-white:  #fff;
  --color-black: #000;
  --color-black-rga: 0, 0, 0;
  --color-white-rga: 255, 255, 255;
  --color-gray-3-rga: 119, 119, 119;
  --color-red-1: #ef3030;
  --color-red-2: #ffbfbf;
  --color-orange-1: #ef5b30;
  --color-yellow-1: #efe930;
  --color-yellow-2: #bdad34;
  --color-blue-1: #497b92;
  --color-blue-2: #b0d2e1;
  --color-green-1: #60b760; // Green on light
  --color-green-2: #74d974; // Green on dark
  --color-green-3: #eaf9ea;
  --color-gray-1: #444;
  --color-gray-2: #777777;
  --color-gray-3: #a9a9a9;
  --color-gray-4: #ebebeb;
  --color-gray-5: #f5f4f4;

  --font-main: 'Helvetica Neue', sans-serif;

  --max-width-content: 1100px;
}

// SCSS VARIABLES