// HEADER ===========================================

.banner {
  background: var(--color-gray-5);
  border-bottom: 1px solid var(--color-gray-4);
  padding-top: 2rem;
  padding-bottom: 1.5rem;
  text-align: center;
}

.logo {
  width: 100%;
  max-width: 240px;
  margin: -1.3rem auto 2rem;
}
.step-indicator {
  background: linear-gradient(180deg, 
    rgba(0,0,0,0) calc(50% - 1px), 
    rgba(var(--color-gray-3-rga),1) calc(50%), 
     rgba(0,0,0,0) calc(50% + 1px)
  );
  display: inline-block;
  margin-top: 5px;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: inline-block;
      margin-left: 1rem;
      color: var(--color-gray-2);
      padding: .3rem .8rem;
      background: var(--color-gray-5);
      border: 2px solid var(--color-gray-2);
      
      &:first-child {
        margin: 0;
      }

      &.active {
        background: var(--color-gray-2);
        border: 2px solid var(--color-gray-2);
        color: var(--color-white);
      }

      &.checked {
        background: var(--color-green-1);
        color: var(--color-white);
        border: 2px solid var(--color-green-1);

        @media screen and (max-width: 350px) {
          i {
            display: none;
          }
        }
      }
    }
  }
}

@include respond-above(l) {
  .step-indicator {
    float: right;
  }

  .logo {
    float: left;
    margin: -1.3rem 0 0;
  }
}