#payment {
  background: var(--color-gray-5);

  .content-wrapper {
    > .row {
      margin-bottom: 1.5rem;
    }
  }

  .other-contact {
    margin-top: 1rem;
    font-size: .9rem;

    @include respond-below(s) {
      margin-top: 4rem;
    }
  }

  .your-info {
    background: var(--color-gray-5);
    padding: 2rem;
    margin-top: 2rem;

    @include respond-below(s) {
      margin: 2rem -2rem -2rem -2rem;
    }
  }

  .banner {
    background: var(--color-white);
    border: 0;
  }

  #cupman {

    @include respond-above(s) {
      padding: 2rem 3rem;
    }

    .btn {
      background: var(--color-green-1);
      padding: 1rem;
      font-size: 1.5rem;
      border-radius: .5rem;
      cursor: pointer;

      &:hover {
        background: var(--color-gray-1);
      }
    }
  }

  #klarna-klarna-payments-instance-pay-later-main {
    max-width: none !important;
  }

  .ng-scope {
    .paymentMethod {

      &.selected {
        label {
          background: var(--color-green-3);

          &:before {
            @include icon;
            content: "\f058";
            font-weight: 700;
            margin-right: 1rem;
          }

          &:hover {
            border: 1px solid var(--color-gray-3);
            box-shadow: none;
          }
        }
      }

      label {
        &:before {
          @include icon;
          content: "\f111";
          font-weight: 500;
          margin-right: 1rem;
        }
      }
    }

    label {
      border: 1px solid var(--color-gray-3);
      border-radius: .5rem;
      padding: .8em;
      display: flex;
      align-items: center;
      transition: all .2s ease;
      cursor: pointer;

      &:hover {
        border: 1px solid var(--color-white);
        box-shadow: 0 10px 20px rgba(0,0,0, 0.3), 0px 0px 20px rgba(0,0,0, 0.3);
      }

      img {
        max-height: 25px;
        margin-right: 5px;
        border-radius: 4px;
      }

      img[src*="visa.png"],
      img[src*="mastercard.png"] {
        border: 1px solid var(--color-gray-3);
      }
    }
  }

  .tournament-info {
    background: var(--color-white);
    padding: 2rem;
    margin-top: 1rem;

    @include respond-below(s) {
      h4 {
        font-size: 80%;
      }
    }


    i {
      margin: 0;
    }

    h2 {
      margin: 0;

      &:nth-of-type(2) {
        margin-top: 2rem;
      }
    }

    h4,
    h3 {
      margin: 1rem 0 .3rem;
    }

    h3 {
      margin: 0 0 .5rem;
    }

    ul {
      list-style: none;
      padding: 0;

      h4 {
        margin-bottom: 0;
      }
    }
  }

  footer {
    background: var(--color-white);
  }

  .participant-contact{
    background: var(--color-white);
  }

  .ano-products {
    padding: 2rem;
    background: var(--color-white);
    margin-bottom: 2.5rem;

    h3 {
      margin-top: 0;
    }

    ul {
      margin: 0;
      list-style: none;
      padding: 0;
    }
  }
}

.default-status {
  margin-top: 1rem;
  padding: 1rem 1.5rem;
  background: var(--color-gray-5);

  i {
    margin-left: 0;
  }

  p {
    margin: .3rem 0;
  }
}

input.not-valid {
  border: 1px solid var(--color-red-1);
}

.error {
  margin: 0 3rem 2rem;
  background: var(--color-red-1);
  color: var(--color-white);
  display: block;
  padding: 1rem;
  font-weight: bold; 
}

.error-disabled {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100000;
    background: var(--color-white);
    opacity: .5;
  }
}

.p-status {
  font-size: 1.3rem;
  padding: 2rem;

  @include respond-below(s) {
    font-size: 1.1rem;
    padding: 1rem;
  }

  &.paid,
  &.settled {
    background: var(--color-green-1);
    color: var(--color-white);
  
    i {
      margin-left: 0;
    }
  }
  &.cancelled {
    background: var(--color-red-1);
    color: var(--color-white);
  }

  &.refunded {
    background: var(--color-yellow-1);
    color: var(--color-black);
  }
}
