// COLUMNS ===========================================
.row {
  position: relative;

  &:after {
    content: "";
    display: block;
    clear: both;
  }

  &.gutter {
    > .col {
      padding-left: 2rem;
      padding-right: 2rem;

      @include respond-below(s) {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }

    > .col-i {
      padding-left: .5rem;
      padding-right: .5rem;
    }
  }
}

.col,
.col-i { 
  float: left;
  width: 100%;

  &.right {
    text-align: right;
  }
}

//DEFAULT
.d-1 { width: 10%; }
.d-2 { width: 20%; }
.d-3 { width: 30%; }
.d-4 { width: 40%; }
.d-5 { width: 50%; }
.d-6 { width: 60%; }
.d-7 { width: 70%; }
.d-8 { width: 80%; }
.d-9 { width: 90%; }
.d-10 { width: 100%; }

// SMALL DEVICES
@include respond-above(s) {
  .s-1 { width: 10%; }
  .s-2 { width: 20%; }
  .s-3 { width: 30%; }
  .s-4 { width: 40%; }
  .s-5 { width: 50%; }
  .s-6 { width: 60%; }
  .s-7 { width: 70%; }
  .s-8 { width: 80%; }
  .s-9 { width: 90%; }
  .s-10 { width: 100%; }
}
// MEDIUM DEVICES
@include respond-above(m) {
  .m-1 { width: 10%; }
  .m-2 { width: 20%; }
  .m-3 { width: 30%; }
  .m-4 { width: 40%; }
  .m-5 { width: 50%; }
  .m-6 { width: 60%; }
  .m-7 { width: 70%; }
  .m-8 { width: 80%; }
  .m-9 { width: 90%; }
  .m-10 { width: 100%; }
}
// LARGE DEVICES
@include respond-above(l) {
  .l-1 { width: 10%; }
  .l-2 { width: 20%; }
  .l-3 { width: 30%; }
  .l-4 { width: 40%; }
  .l-5 { width: 50%; }
  .l-6 { width: 60%; }
  .l-7 { width: 70%; }
  .l-8 { width: 80%; }
  .l-9 { width: 90%; }
  .l-10 { width: 100%; }
}

// LARGE DEVICES
@include respond-above(xl) {
  .xl-1 { width: 10%; }
  .xl-2 { width: 20%; }
  .xl-3 { width: 30%; }
  .xl-4 { width: 40%; }
  .xl-5 { width: 50%; }
  .xl-6 { width: 60%; }
  .xl-7 { width: 70%; }
  .xl-8 { width: 80%; }
  .xl-9 { width: 90%; }
  .xl-10 { width: 100%; }
}

// INFINITE (NO COLLAPSE)
.i-1 { width: 10%; }
.i-2 { width: 20%; }
.i-3 { width: 30%; }
.i-4 { width: 40%; }
.i-5 { width: 50%; }
.i-6 { width: 60%; }
.i-7 { width: 70%; }
.i-8 { width: 80%; }
.i-9 { width: 90%; }
.i-10 { width: 100%; }