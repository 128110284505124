@keyframes backgroundshift {
  10%  {
    background-color: var(--color-orange-1);
    color: var(--color-white);
  }
  100% {
    background-color: transparent;
    color: var(--color-black);
  }
}

.participant {
  margin-bottom: 1rem;
  overflow: hidden;
}

.participant-info {
  background: var(--color-gray-1);
  padding: 1rem;
  color: var(--color-white);
  overflow:hidden;
  display: flex;

  @include respond-above(s) {
    padding: 1rem 1.5rem;
  }

  .name {
    font-weight: 700;
    font-size: .9rem;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    
    display: block;
    flex: 1;
    
    @include respond-above(xs) {
      font-size: 1rem;
    }
    
    @include respond-above(s) {
      font-size: 1.5rem;
    }
  }
}

.participant-contacts-edit {
  .btn {
    margin-top: 1rem;
    display: block;
    width: 100%;

    @include respond-above(s) {
      display: inline-blick;
      width: auto;
    }
  }
}

.participants_wrapper {
  padding-top: 3rem;

  button {
    margin-top: 0;
  }

  .products-row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -0.5rem;
    button {
      margin: 0.5rem;
      min-width: fit-content;
    }
  }
}

.participant-actions {
 
  margin: auto;
  
  @include respond-below(s) {
    position: relative;
  }
  
  button {
    font-size: .8rem;
    border: 0;
    background: transparent;
    color: var(--color-white);
    margin-left: .1rem;

    @include respond-above(xs) {
      font-size: 1rem;
      margin-left: 1rem;
    }

    &.participant-edit {
      color: var(--color-blue-2);
    }
  }
}

.participant-sum {
  
  font-size: .9rem;

  @include respond-above(xs) {
    font-size: 1rem;
  }
  
  @include respond-above(s) {
    font-size: 1.5rem;
  }
}

.participant-accordion {
  &:before {
    @include icon;
    content: "\f078";
    position: relative;
    transition: transform .2s linear, top .2s linear;
  }
}
.active {
  .participant-accordion {
    &:before {
      @include icon;
      content: "\f078";
      top: -1px;
      transform: rotate(180deg);
    }
  }
}

.participant-contacts {
  padding: .5rem 2rem 2rem 2rem;
  margin-bottom: 1rem;
  background: var(--color-gray-4);
  
  h4 {
    font-weight: bold;
    font-size: .8rem;
    margin-bottom: .5rem;
  }

  a {
    color: var(--color-orange-1);
  }
}

.part_info_choice {
  padding: 3rem;
  border: 1px solid var(--color-gray-4);
  margin-bottom: 1rem;
  animation-duration: .5s;

  .extra {
    display: none;

    &.active {
      display: block;
    }
  }

  @include respond-below(s) {
    &:not(.marked) .part_info_extra {
      display:none;
    }

    

    .input-container {
      margin-left: -23px;
      margin-top: -19px;
    }

    .part_info_extra {
      font-size: 14px;
    }

    padding: 2rem;
    padding-bottom: 1rem;
  }

  &.marked {
    //background: var(--color-gray-5);
    animation-name: backgroundshift;
    border: 1px solid var(--color-orange-1);
  }

  .part_info_extra {
    margin-top: 2rem;
  }
}

.summary_info {
  .input-container {
    margin-bottom: 2rem;
  }
}

.products-column {
  @include respond-below(s) {
    display: none;
  }

}

.tight_p {
  margin: .5rem 0;
  overflow: hidden;

  &:last-child {
    margin: 0;
  }
}

.participant-contact {
  background: var(--color-gray-4);
  padding: 1rem 2rem 2rem;

  @include respond-below(s) {
    padding: 1rem 2rem 2rem;
    margin: 0 -2rem;
  }

  legend {
    display: table;
    float: left;
    margin: 0;
    padding: 0;
    width: 100%;

    + * {
      clear: both;
    }
  }

  .btn {
    float: right;
  }

  .col {
    @include respond-above(s) {
      &:last-child {
        padding-left: .5rem;
      }

      &:first-child {
        padding-right: .5rem;
      }
    }

    @include respond-below(s) {
      input {
        max-width: none;
      }
    }
  }
}

.product-presentation {
  background: var(--color-gray-4);
  padding: 1rem;

  .row {
    padding: .4rem .8rem;

    &:nth-child(odd) {
      background: white;
    }

    .right {
      float: right;
    }
  }
}

#new-participant {
  > .row {
    input[type="text"] {
      width: calc(100% - 1rem);

      @include respond-below(s) {
          max-width: none;
          width: 100%;
      }
    }
  }
}