fieldset {
  border: 0;
  margin: 1rem 0 0;
  padding: 0;
  font-size: 14px;

  label {
    font-size: 14px;
    margin: 1rem 0 .5rem;
  }

  &.summary {
    padding: 1rem;
  }
}

.login-form {
  float: none;
  margin: auto;
  background: var(--color-gray-4);
  padding: 1rem 3rem 3rem;
}

legend,
label {
  font-size: 1rem;
  margin: 1rem 0 .5rem;
}

legend {
  font-weight: 700;
}

label {
  font-weight: 700;
  display: block;
}

.input-container {
  position: relative;

  label {
    margin-left: .3rem;
    display: inline;
  }

  label {
    &:before {
      color: var(--color-orange-1);
      transition: all .2s ease;
    }

    &:hover {
      &:before {
        color: var(--color-black);
      }
    }
  }
}

select {
  appearance: none;
  border: 1px solid var(--color-gray-3);
  padding: .5rem 2.5rem .5rem .5rem;
}

input[type="date"] {
  font-family: var(--font-main);
  padding: .5rem 1rem;
  border-radius: 0;
  border: 0;
  font-weight: bold;
  font-size: 1rem;
}
.date-select-wrapper__inner {
  margin-left: -0.25rem;
  padding: .25rem;
  background: none;
  display: inline-block;
  input {
    border: 1px solid var(--color-gray-3);
  }
}
.date-select-wrapper__inner.invalid {
  background: var(--color-orange-1);
  input {
    border: 1px solid white;
  }
}


[type="text"],
[type="number"],
[type="password"],
[type="tel"]{
  border: 1px solid var(--color-gray-3);
  padding: .5rem;
  width: 100%;
  max-width: 350px;
  border-radius: 0;
}

.login-form {
  max-width: 450px;
  [type="text"],
  [type="number"],
  [type="password"] {
    max-width: none;
  }

  .error {
    > div {
      background: var(--color-red-1);
      color: var(--color-white);
      padding: 1rem;

      h3 {
        margin-top: .3rem;
      }

      p {
        margin-left: 5px;
      }
    }
  }
}

textarea {
  resize: vertical;
  min-width: 100%;
  height: 150px;
  border: 1px solid var(--color-orange-1);
  padding: 1rem;
  font-family: var(--font-main);
  min-height: 50px;
  font-size: 1rem;
}

// RADIO BUTTONS
[type="radio"] {
  opacity: 0;
  width: 0;
  margin: 0;
  height: 0;
  display: block;

  + label {
    display: flex;
    margin: 0;
    font-size: 1.0rem;
  }

  &:focus {
    + label {
      
      &:before {
        @include focus-header;
      }
    }
  }
  
   + label:before {
    @include icon;
    content: "\f111";
    font-size: 1.0rem;
    font-weight: 300;
    margin-right: 0.5em;
   }
   
   &:checked {
      + label:before {
        content: "\f192";
        font-weight: 500;
      }
   }
}


// CHECKBOXES
[type="checkbox"] {
  opacity: 0;

  &:focus {
    + label {
      
      &:before {
        @include focus-header;
      }
    }
  }
  
  + label:before {
    @include icon;
    content: "\f0c8";
    font-size: 1.2rem;
    font-weight: 500;
    left: 0;
    position: absolute;
    top: 2px;
    padding: .3rem;

    @include respond-above(s) {
      top: 1px;
    }
  }
  
  &:checked {
    + label:before {
      content: "\f14a";
      font-weight: 900;
    }
  }
}

.summary {
  label {
    &:before {
      top: -4px;
      left: -4px;
    }
  }
}

label[for*="contact-notify-"] {
  &:before {
    padding: 0;
  }
}