// SUMMARY ===========================================

.summary {
  fieldset {
    background: var(--color-gray-5);
    padding: 1rem;
    
    @include respond-below(s) {
      margin-right: -1rem;
      margin-left: -1rem;
    }
  
  }



  tr,
  table {
    width: 100%;
    border-collapse: collapse;

    .disabled-text {
      font-style: italic;
    }
  }

  tr:nth-child(even) {
    background: var(--color-white);

    @include respond-above(s) {
      background: transparent;
    }
  }

  th {
    text-align: left;
    padding: 0 0 1rem .5rem;
  }

  td,
  th {
    padding: .5rem;
    text-align: right;

    &[colspan="4"] {
      padding: .5rem 0;
    }

    &:first-child {
      text-align: left;
    }

    &.warning {
      background: var(--color-gray-5);
    }
  }

  .summary-sum {
    background: transparent !important;

    td {
      font-weight: 700;
      font-size: 1.2rem;
    }
  }

  .disabled-text {
    color: var(--color-gray-2);
  }

  .allocate-participant {
    background: transparent;
    margin: 0;
    padding: 0;
  }

  // Radio buttons startpage
  .allocate-participant {
    .input-container {
      label {
        &:before {
          top: 0;
          left: 0;
        }
      }
    }
  }
}