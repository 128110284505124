.loading {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 200;
  background: rgba(var(--color-white-rga), .95);
  display: none;

  &.active {
    display: block;
  }
}

.loading-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 25%;
  max-width: 120px;
  font-weight: 700;
}