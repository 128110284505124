@import "base/base-imports/mixins.scss";

// BUTTONS ===========================================
a.btn {
  text-decoration-line: none;
}

.btns {
  @include respond-below(m) {
    display:flex;
    justify-content: center;
    flex-wrap: wrap;
    .btn {
      margin-left: 0.5rem !important;
      margin-right: 0.5rem ;
    }
  }
}

.overview_btn {
  display: inline-flex !important;
  align-items: center;
  gap: .4rem;
  text-decoration: none !important;
  line-height: 1rem;
  justify-content: center;

  @include respond-below(m) {
    width: 100%;
    margin-right: 0 !important;
  }
}

.btn {
  background: transparent;
  border: 0;
  color: var(--color-white);
  font-size: 1rem;
  font-weight: 700;
  padding: .6rem 1.3rem .55rem;
  margin-left: 0;
  display: inline-block;
  position: relative;
  margin-top: 1rem;
  transition: all .2s ease;
  margin-right: 0;

  &:nth-of-type(n+2) {
    margin-left: 1rem;
  }

  .loading-circle {
    max-height: 14px; 
    max-width: 14px;
    display: inline; 
    position: relative; 
    top: 2px;
  }

  &.no-margin {
    margin-top: 0;
  }

  &.tool {
    margin: 0;
    padding: 0;
    color: var(--color-orange-1);

    &:hover {
      color: var(--color-black);
    }

    &:first-child {
      padding-left: 1rem;
      padding-right: .2rem;
    }

    &:last-child {
      padding-right: 1rem;
      padding-left: .2rem;
    }

    &:disabled {
      opacity: 50%;
      background: transparent !important;
      color: var(--color-orange-1) !important;
    }
  }

  &.right {
    float: right;
    margin-left: .5rem;
    margin-right: 0;
  }

  .fake-btn-text {
    padding-bottom: 5px;
  }

  &.next {
    padding-left: 1.3rem;
    padding-right: 1rem;
  }

  &.previous {
    padding-left: 1rem;
    padding-right: 1.3rem;
  }

  // Dark buttons
  &.dark-primary {
    background: var(--color-orange-1);

    &:hover {
      background: var(--color-black);
    }
  }

  &.dark-secondary {
    background: var(--color-blue-1);

    &.cleared {
      background: var(--color-green-1);
    }

    &:hover {
      background: var(--color-black);
    }
  }

  &.hollow-secondary {
    color: var(--color-blue-1);
    box-shadow:inset 0px 0px 0px 1px var(--color-blue-1);

    &:hover {
      color: var(--color-black);
      box-shadow:inset 0px 0px 0px 2px var(--color-black);
    }
  }

  &:disabled {
    background: var(--color-gray-3) !important;
    color: var(--color-white) !important;
    border: 0 !important;
    box-shadow: none !important;

    &.saving {
      background: var(--color-gray-1) !important;
    }
  }

  &.save {
    background: var(--color-green-1);

    &:hover {
      background: var(--color-black);
    }
  }

  &.delete {
    color: var(--color-orange-1);
    box-shadow:inset 0px 0px 0px 1px var(--color-orange-1);

    &:hover {
      color: var(--color-black);
      box-shadow:inset 0px 0px 0px 2px var(--color-black);
    }
  }

  &.disabled {
    background: var(--color-gray-3);
  }

  &.prev {
    color: var(--color-orange-1);
    box-shadow:inset 0px 0px 0px 1px var(--color-orange-1);

    &:hover {
      color: var(--color-black);
      box-shadow:inset 0px 0px 0px 2px var(--color-black);
    }
  }

  // Light buttons
  &.light-primary {
    box-shadow:inset 0px 0px 0px 2px var(--color-green-2);
  }
  
  &.light-secondary {
    box-shadow:inset 0px 0px 0px 2px var(--color-white);
  }

  &.active {
    font-weight: 500;
    margin: 0;
    color: var(--color-green-2);
    font-size: 1rem;
    padding: .4rem .4rem .3rem .7rem;

    @include respond-above(xs) {
      color: var(--color-white);

      &:hover {
        background: var(--color-red-1);
        color: var(--color-white);
        border-color: var(--color-red-1);
      }
    }
    
    @include respond-below(s) {
      span {
        @include visually-hidden;
      }
    }

    @include respond-above(s) {
      //border: 1px solid var(--color-red-2);
      padding: .4rem .8rem .3rem .7rem;
    }

    &:disabled {
      opacity: 0;
    }
  }

  &.paused {
    font-weight: 500;
    margin: 0;
    color: var(--color-yellow-1);
    font-size: 1rem;
    padding: .4rem .4rem .3rem .7rem;

    @include respond-above(xs) {
      color: var(--color-white);

      &:hover {
        background: var(--color-green-1);
        color: var(--color-white);
        border-color: var(--color-green-1);
      }
    }
    
    @include respond-below(s) {
      span {
        @include visually-hidden;
      }
    }

    @include respond-above(s) {
      font-size: 1rem;
      //border: 1px solid var(--color-green-2);
      padding: .4rem .8rem .3rem .7rem;
    }

    &:disabled {
      opacity: 0;
    }
  }
}