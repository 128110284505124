// GENERAL ===========================================

// ELEMENTS
html {
  box-sizing: border-box;
}
*, 
*:before, 
*:after { 
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: var(--font-main);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

p {
  margin: 0;
  line-height: 1.4rem;
}

a {
  color: var(--color-green-2);
}

i {
  margin-left: .3rem;
}

hr {
  border: 0;
  height: 1px;
  color: var(--color-gray-3);
  background: var(--color-gray-3);
}

h4 {
  span {
    margin-top: .5rem;
    color: var(--color-gray-1);
    font-size: .8rem;
    display: block;
  }

  &.sr-only {
    margin: 0;
  }
}

@include respond-below(s) {
  h1 {
    font-size: 1.5em;
  }
  h2 {
    font-size: 1.3em;
  }
  h3 {
    font-size: 1.05em;
  }
}

// ID'S
#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

// CLASSES
.fake-label {
  font-weight: 700;
}

.paragraphs {
  h3 {
    margin: 1.5rem 0 .5rem;
  }

  h4 {
    margin: 1.5rem 0 .5rem;
  }
}

.sr-only:not(:focus):not(:active) {
  @include visually-hidden;
}

.warning {
  color: var(--color-red-1);
  font-weight: 700;
}

.text-right {
  text-align: right !important;
}

@include respond-above(s) {
  .mobile {
    display: none;
  }

  .desktop {
    @include reset-visually-hidden;
  }
}

@include respond-below(m) {
  .mobile {
    display: inline;
  }

  .desktop {
    @include visually-hidden;
  }
}

.devider {
  border-top: 1px solid var(--color-gray-3);
}

.dark {
  background: var(--color-gray-1);
  color: var(--color-white);
}

.red {
  font-weight: 700;
  color: var(--color-red-1);
}

.green {
  font-weight: 700;
  color: var(--color-green-1);
}
.yellow {
  font-weight: 700;
  color: var(--color-yellow-2)
}

.align-h {
  list-style-type: none;

  &.right {
    li {
      margin-left: .5rem;
    }
  }

  li {
    display: inline-block;
  }
}

.nav-btns {
  margin-top: 1rem;
}

ol {
  padding-left: 1rem;

  li {
    margin-bottom: 1rem;
  }
}

.no-bullets {
  list-style: none;
  padding: 0;
}

.bold {
  font-weight: 700;
}

.italic {
  font-style: italic;
}

.alert {
  background: var(--color-gray-2) !important;
  color: var(--color-white);
  font-weight: bold;
  margin-top: .5rem;
  padding: .7rem .8rem .8rem !important;
}

.done {
  background: var(--color-green-1) !important;
  color: var(--color-white);
  font-weight: bold;
  margin-top: .5rem;
  padding: .7rem .8rem .8rem !important;
}

.add-contact {
  @include respond-below(s) {
    margin-bottom: 2rem;
  }
}

.summary_info {
  p {
    margin-bottom: 1rem;
    font-size: 16px;

    @include respond-below(s) {
      font-size: 14px;
    }
  }

  h3 {
    margin: 1.5rem 0 .5rem;
  }

  h4 {
    margin-bottom: .3rem;
  }
}

.invisilble-overlay {
  background: transparent;
}

.modal.confirm-dialog {
  box-shadow: 0px 0px 18px 1px rgba(0,0,0,0.41);
  padding: 3rem 4rem;
  position: fixed;
  border: 1px solid var(--color-gray-3);
  max-width: 600px;
  z-index: 102;

  @include respond-below(s) {
    padding: 2rem 2rem;
  }
}

/*.warning {
  background: var(--color-gray-2);
  color: var(--color-white);
  font-weight: bold;
  margin-top: .5rem;
  padding: .7rem .8rem .8rem !important;
}*/