// CONTENT ===========================================

.content-wrapper {
  margin: auto;
  max-width: var(--max-width-content);
  padding: 2rem 0;
  width: 100%;
  min-height: calc(100vh - 440px);
}

.info-highlight {
  background: var(--color-gray-1);
  color: var(--color-white);
  padding: 1.5rem;
  margin: 1rem 0;
  font-size: 0.9em;

  h3 {
    margin-top: 0;
  }
  ul {
    margin-bottom: .5rem;
  }

  li {
    margin: .5rem 0 0;
  }

  @include respond-above(s) {
    font-size: 1em;
  }
}

.nav-btns {
  text-align: right;
  display: flex;
  justify-content: center;

  @include respond-above(s) {
    justify-content: flex-end
  }
}

.allocate-participant {

  margin-top: 0;

  legend {
    margin-bottom: 0,
  }

  .input-container {

    margin-bottom: 1em;

    label {
      font-weight: 500;
      margin-left: 6px;
      font-size: 0.9rem;

      &:before {
        font-size: 0.9rem;
        bottom: 0;
        //padding: .25rem;
      }

      @include respond-above(s) {
        font-size: 1.0rem;

        &:before {
          font-size: 1.0rem;
        }
      }
    }
  }
}

// IMPORTS
@import "content-components/summary";
@import "content-components/modal";