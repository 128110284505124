body.ReactModal__Body--open {
  height: 100vh;
  overflow-y: hidden;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(var(--color-black-rga), 0.7);
  width: 100%;
  height: 100%;
  z-index: 101;
}

.invisible {
  position: fixed;
  top: 0;
  left: 0;
  background: transparent;
  width: 100%;
  height: 100%;
  z-index: 101;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--color-white);
  padding: 2rem 2rem 2rem 2rem;
  width: 90%;
  max-width: 820px;
  overflow: auto;
  max-height: calc(100% - 2rem);

  @include respond-above(s) {
    padding: 2rem 2rem 2rem 2rem;
  }

  aimg {
    max-width: 100%;
    margin-top: 2rem;
  }

  button {
    img {
      margin-top: 0;
    }
  }
}

.close {
  position: absolute;
  right: 0rem;
  top: -0.5rem;
  color: var(--color-blue-1);
  font-size: 2rem;
}