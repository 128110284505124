.participants-product {

  @include respond-below(s) {
    .buttons {
      position: absolute;
      top: 0;
      height: 100%;
      

      button {
        font-size: 1.5rem;
        padding: 0.5rem !important;
        height: 100%;
      }
    }

    .product-name,
    .product-count {
      margin-left: 85px;
    }

    .row{
      font-size: 0.9em;
    }
  }


  label {
    display: inline-block;
    margin: 0;
    font-weight: 400;

    &:before{
      color: var(--color-orange-1);
      top: 6px;
      left: 6px;
      transition: all .2s ease;
    }

    &:hover {
      &:before {
        color: var(--color-black);
      }
    }
  }

  [type="checkbox"] {
    margin-right: 10px;
  }

  .row {
    padding: .4rem;

    &:nth-child(even) {
      background: var(--color-gray-4);
    }
  }

}

.info-highlight {
  h4 {
    margin-top: 0;
  }
}

.accept {
  float: right;
  padding: 1rem 1.5rem;
  background: var(--color-gray-4);

  label {
    &:before {
      top: .7rem !important;
      left: 1rem !important;
      font-size: 1.5rem;
    }
  }
}