
.smartphone-wrapper__outer {
  margin-top: 2rem;
}
.smartphone-wrapper {
  position: relative;
  display:inline-block;
  min-width: 100%;
  margin-left: -20px;
  margin-right: -20px;

  @include respond-above(s) {
    min-width: auto;
  }
}

.publish-acc-container {
  display: flex;
  flex-wrap: wrap;

  @include respond-below(m) {
    justify-content: center;
  }
}

.msg-wrapper {
  position: absolute;
  top: 30%;
  left: 15%;
  max-width: 55%;
}

.msg-txt__wrapper {
  background: #e9e9ea;
  width: calc(100%);
  padding: 10px;
  border-radius: 12px;
}

.no-bullets{
  h4 {
    margin-bottom: 0;
  }
}

.msg-txt {
  font-size: .8rem;
  line-height: initial;
  white-space: pre-wrap;
}

.msg-img {
  width: 100%;
  display: block;
}

.fake-msg-link {
  color: #3478f6;
  text-decoration: underline;
  word-wrap: break-word;
}

.smartphone-frame {
  max-width: 100%;
}