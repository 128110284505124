@import "base/base-imports/_variables.scss";
@import "base/base-imports/mixins.scss";
@import "base/base-imports/forms.scss";
@import "base/base-imports/loading.scss";

h1 {
  margin-bottom: 0;
}

button {
  cursor: pointer;
  i {
    margin-left: 0;
  }
}

.locked {
  overflow: hidden;
  height: 100vh;
}

.logo {
  width: 100%;
  max-width: 240px;
  margin: -1.3rem auto .1rem;
}

.black {
  color: var(--color-black);
}

.debugg{
  button {
    margin: .2rem;
    background: black;
    color: white;
    border: 0;
    font-weight: bold;
    padding: .2rem .5rem;
  }
}

.sum-paid {
  margin-bottom: 2rem;
  font-weight: 700;
}

.row.contact {
  align-items: center;
  
  * {
    vertical-align: middle;
  }

  a {
    display: inherit;
  }

  @include respond-above(m) {
    display: flex;
  }

  /*> .col {
    align-items: center;

    @include respond-above(m) {
      display: flex;
    }
  }*/

  .col{
    &.actions {
      justify-content: flex-end
    }
  }
}

.send-status {
  display: none;
  margin-top: 1rem;

  &.sent {
    display: block;
    color: var(--color-green-1);
    font-weight: bold;
  }

  &.failed {
    display: block;
    color: var(--color-red-1);
    font-weight: bold;
  }
}

.status {
  float: left;

  i {
    margin: 0 .5rem 0 0;
  }
}

.participant-amount {
  font-weight: 700;
  margin-left: .2rem;

  @include respond-above(xs) {
    margin-left: .8rem;
  }

  @include respond-above(s) {
    margin-left: 1.1rem;
    font-size: 1.2rem;
  }
}

.participant-actions {
  i {
    @include respond-below(s) {
      margin-left: 0;
    }
  }
}

.separator {
  height: 100%;
  border-left: 1px solid var(--color-gray-3);
  margin: 0 1.5rem 0 1rem;
}

.align-middle {
  display: flex;
  align-items: center;
}


.overview-status {
  margin-bottom: 2rem;

  button {
    margin-top: 0;
    margin-bottom: .2rem;
  }
}

.row.gutter .leader-actions,
.row.gutter .complete-info,
.row.gutter .refunded-info,
.row.gutter .next-step {
  margin: 0 0 1rem;
  padding: 1.5rem 1.0rem;

  @include respond-above(s) {
    padding: 1.5rem;
  }

  i {
    margin-left: 0;
  }

  &.paid {
    background: var(--color-gray-4);
   // color: var(--color-white);

    div > i {
      color: var(--color-green-1);;
    }
  }

  &.refunded {
    background: var(--color-gray-1);
    color: var(--color-white);

    div > i {
      color: var(--color-green-2);
    }
  }

  &.payLater {
    background: var(--color-gray-4);

    .klarna {
      height: 2rem;
    }
  }
  
  .btn {
    margin: 1rem 1rem 0 0;

    &.visible {
      display: inline-block;
      background: var(--color-white);
      color: var(--color-black);

      &.save {
        background: var(--color-green-1);
        color: var(--color-white);
      }
    }

    &.cancel {
      background: transparent !important;
      font-weight: normal;
      color: var(--color-black) !important;
      box-shadow: inset 0px 0px 0px 1px var(--color-black);
      text-decoration: underline;
    }
  }
  #cupman {
    button{
      display: inline-block;
      background: var(--color-green-1);
      padding: 1rem;
      font-size: 1.5rem;
      border-radius: .5rem;
      color: var(--color-white);
      cursor: pointer;

      &:hover {
        background: var(--color-gray-1);
      }
    }
  }

  &.unpaid {
    background: var(--color-gray-4);

    div > i {
      color: var(--color-red-1);
    }

    &.overdue {
      background: var(--color-orange-1);
      color: var(--color-white);

      div > i {
        color: var(--color-white);
      }

      .btn {
        color: var(--color-black);
        background: var(--color-white);
  
        &.cancel {
          background: transparent !important;
          font-weight: normal;
          color: var(--color-white) !important;
          text-decoration: underline;
        }
      }
    }
  }
}


.overview {
  .dd-enter {
    opacity: 0;
    transform: scaleY(0);
    
  }
  .dd-enter-active {
    opacity: 1;
    transform: scaleY(1);
  }

  // -------

  .dd-exit {
    opacity: 1;
    transform-origin: top;
    transform: scaleY(1);
  }

  .dd-exit-active {
    opacity: 0;
    transform: scaleY(0);
  }


  
  .participant-contacts {
    font-weight: 400;
    padding: .8rem;
    transition: all .2s linear;
    transform-origin: top;

    @include respond-above(xs) {
      padding: .5rem 2rem 2rem 2rem;
    }


    .fa-stack { 
      font-size: 0.8em;
      margin-right: .3rem;
      top:2px;
    }
  
    .fa-envelope-open {
      top: -2px;
    }

    .fa-envelope {
      top: -1px;
    }
  
    i { 
      vertical-align: middle;
      margin-left: 0;
    }

    a {
      @include respond-below(m) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        display: block;
      }
    }
  }

  .btn-contact {
    background: var(--color-orange-1);
    color: var(--color-white);
    border-radius: 0;
    font-weight: 700;
    margin-right: .2rem;
    padding: .3rem .5rem;
    border: 1px solid;

    &:hover {
      border: 1px solid;
      color: var(--color-orange-1);
      background: transparent;
    }

    &:disabled {
      opacity: 50%;
      background: transparent !important;
      color: var(--color-orange-1) !important;
    }
  }

  .indicator {
    font-size: .8rem;
    position: relative;
    top: -1px;
    display: none;

    @include respond-above(xs) {
      display: inline;
    }

    &.active {
      color: var(--color-green-2);
    }

    &.paused {
      color: var(--color-yellow-1);
    }
  }

  .participant-state {
    i, img {
      margin-right: .3rem;
    }
    
  }

  .right {
    float: right;
  }

  .product-information {
    background: var(--color-gray-4);
    font-weight: normal;

    .row {
      padding: .4rem .8rem;

      &:nth-child(odd) {
        background: var(--color-white);
      }
    }
  }

  .contact {
    &:nth-child(even) {
      background: var(--color-white);
    }
    &:nth-child(odd) {
      background: var(--color-gray-5);
    }

    .col {
      padding: .5rem .5rem .6rem;
    }
  }

  .info-highlight {
    padding: .6rem;
    display: inline-block;
    border-radius: 25px;

    span {
      float: none;
      margin-right: 2rem;

      &:last-child {
        margin: 0;
      }
    }
  }

  .participant-info {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .main-info {
      width: auto;
      flex: 1;
      width: 40%;

      @include respond-above(s) {
        padding-bottom: 0;
      }

      .name {
        font-size: 1rem;
      }
    }
  }
}

.explenation-wrapper {
  margin-bottom: 2rem;
  display: inline-block;

  h3 {
    display: inline;
  }

  h4 {
    display: inline; 
    margin-right: 1rem;
  }
}

.explenation {
  margin-right: 1rem;
}

.fa-circle {
  color: var(--color-gray-1);
}

.fa-stack-1x {
  &.fa-envelope-open {
    top: -2px;
  }
}

.pay-link {
  background: var(--color-gray-1);
  color: var(--color-white);
  padding: 1.5rem;
  margin: 1rem 0;
}

.action-button {
  display: inline-block;
}


.paid-wrapper {
  .participant-accordion {
    &:before {
      transform: none;
    }
  }

  .participant {
    margin-bottom: .5rem;

    @include respond-above(s) {
      margin-bottom: 1rem;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.active {

      .participant-accordion {
        &:before {
          transform: rotate(180deg);
        }
      }
    }
  }

  .accordion-paid {
    margin: 2rem 0 0;

    button {
      font-weight: 700;
      background: var(--color-gray-4);
      border: 0;
      display: block;
      width: 100%;
      text-align: left;
      padding: 1rem;

      &:hover {
        background: var(--color-gray-5);
      }

      &:after {
        @include icon;
        content: "\f078";
        font-weight: 400;
        float: right;
        margin-right: .2rem;
        transition: transform .2s linear, top .2s;
      }
    }
  }

  &.active {
    .accordion-paid {
      button {
        &:after {
          transform: rotate(180deg);
        }
      }
    }
  }

  .paid-inner-wrapper {
    padding: .5rem;
    background: var(--color-gray-5);

    @include respond-above(s) {
      padding: 2rem;
    }
  }
}

.summary {
  padding: 1rem 0;
  background: #fff;

  &:nth-child(2n){
    background: #eee;
  }
  
  .fa-stack {
    @include respond-below(s) {
      display: none;
    }
  }

  .acc-container {
    display: flex;
    flex-wrap: wrap;

    @include respond-below(m) {
      justify-content: center;
    }
  }
}

.acc-container {
  display: flex;
  flex-wrap: wrap;
}

.progress-header {
  margin-bottom: .5rem;
  font-size: 1rem;
  font-weight: 500;
}

.progress-bar_frame {
  border: 1px solid var(--color-gray-3);
  padding: 2px;
  position: relative;

  &:before,
  &:after {
    font-size: .7rem;
    position: absolute;
    bottom: -1rem;
  }

  &:before {
    content: "0%";
    left: -2px;
  }

  &:after {
    content: "100%";
    right: -3px;
  }

  .progress-bar_inner-frame {
    background: var(--color-gray-4);
    overflow: hidden;

    .lines-wrapper {
      position: absolute;
      display: flex;
      top: -2px;
      width: calc(100% - 4px);
      height: 2rem;
      z-index: 100;
  
      .line {
        border-right: 1px solid rgba(var(--color-black-rga), 20%);
        height: 2.5rem;
        flex-grow: 1;
        position: relative;
        top: -.25rem;
  
        &:last-child {
          border: 0;
        }
      }
    }
  }

  .progress-bar {
    height: 1.5rem;
    background: var(--color-green-1);
  }
}

.refunded-info {
  background: var(--color-orange-1);
  color: var(--color-white);
  padding: 2rem;
  margin-bottom: 1rem;
  margin-top: 2rem !important;

}


.complete-info {
  background: var(--color-orange-1);
  color: var(--color-white);
  padding: 2rem;
  margin-bottom: 1rem;

  .fa-stack {
    max-width: 2em;
  }

  .fa-hourglass-end {
    position: relative;
    top: -3px;
  }

  i {
    margin-left: 0;
  }

  li {
    margin: .3rem 0;
  }

  h2 {
    margin-top: 0;
  }

  &.complete {
    background: var(--color-green-1);

    h3 {
      margin-bottom: 0;
    }
  }
}

.acc-container {
  .save {
    &:after {
      @include icon;
      content: "\f078";
      margin-left: .4rem;
      position: relative;
      transition: transform .2s linear, top .2s linear;
    }
  }

  &.active {
    .save {
      &:after {
        top: -1px;
        transform: rotate(180deg);
      }
    }
  }
}

#cupman {
  padding: 2rem;

  @include respond-below(s) {
    padding: 1rem;
  }
}

.ng-scope {
  color: var(--color-black);


  .paymentMethod {
    &.selected {
      label {
        background: var(--color-green-3);

        &:before {
          @include icon;
          content: "\f058";
          font-weight: 700;
          margin-right: 1rem;
        }

        &:hover {
          border: 1px solid var(--color-gray-3);
          box-shadow: none;
        }
      }
    }

    label {
      &:before {
        @include icon;
        content: "\f111";
        font-weight: 500;
        margin-right: 1rem;
      }
    }
  }

  label {
    border: 1px solid var(--color-gray-3);
    border-radius: .5rem;
    padding: .8em;
    display: flex;
    align-items: center;
    transition: all .2s ease;
    cursor: pointer;

    &:hover {
      border: 1px solid var(--color-white);
      box-shadow: 0 10px 20px rgba(0,0,0, 0.3), 0px 0px 20px rgba(0,0,0, 0.3);
    }

    img {
      max-height: 25px;
      margin-right: 5px;
      border-radius: 4px;
    }

    img[src*="visa.png"],
    img[src*="mastercard.png"] {
      border: 1px solid var(--color-gray-3);
    }
  }
}

.tournament-info {
  background: var(--color-white);
  padding: 2rem;
  margin-top: 1rem;

  i {
    margin: 0;
  }

  h2 {
    margin: 0;

    &:nth-of-type(2) {
      margin-top: 2rem;
    }
  }

  h4,
  h3 {
    margin: 1rem 0 .3rem;
  }

  h3 {
    margin: 0 0 .5rem;
  }

  ul {
    list-style: none;
    padding: 0;

    h4 {
      margin-bottom: 0;
    }
  }
}