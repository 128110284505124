// BETA-BANNER ===========================================

.beta-banner {
  padding-bottom: 2rem;
  padding-top: 2rem;

  .beta-banner-content {
    max-width: var(--max-width-content);
    margin: auto;
    padding: 0 2rem;
  }

  p {
    position: relative;

    @include respond-above(s) {
      border-left: 1px solid var(--color-white);
      padding-left: 1rem;
      margin-left: 4.9rem;

      &:before {
        content: "BETA";
        position: absolute;
        left: -5rem;
        top: 2px;
        font-weight: 700;
        font-size: 1.5rem;
      }
    }
  }
}

.beta-banner__btns {
  @include respond-above(s) {
    margin-left: 4.9rem;
  }

  @include respond-above(l) {
    float: right;
    margin-left: 0;
  }

  .btn {
    margin-top: 1.5rem;
  }
}

.beta-banner__info p {
  max-width: 700px;
}