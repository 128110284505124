// MIXINS ===========================================

// BREAKPOINT WIDTHS
$breakpoints: (
  xs: 400px, // Mobile
  s: 576px, // Mobile
  m: 768px, // Tablets
  l: 992px,
  xl: 1200px // Desktop
);

@mixin respond-above($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    // Write the media query.
    @media (min-width: $breakpoint-value) {
      @content;
    }
  // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

// RESPOND BELOW (Breakpoins found in "_variables.scss")

@mixin respond-below($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    // Write the media query.
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }
  // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

@mixin visually-hidden {
  clip: rect(0 0 0 0); 
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap; 
  width: 1px;
}

@mixin reset-visually-hidden {
  clip: initial;
  clip-path: initial;
  height: initial;
  overflow: initial;
  position: initial;
  white-space: initial;
  width: initial;
}

@mixin icon {
  font-family: 'Font Awesome 5 Pro';
  display: inline-block;
  text-decoration: none;
  font-style: normal;
}

@mixin focus-header {
  outline: 2px solid var(--color-black);
  outline-offset: 2px;
}